
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import Project from '@/models/Project';
import moment from 'moment';

@Component({
    name: 'PaymentOfferSelector',
    components: {},
})
export default class PaymentOfferSelector extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private value!: String;

    private isOffers = true;

    private getOfferName(offer: any) {
        const formattedDate = moment(offer.createdAt).format('DD.MM.YYYY');
        return `${offer.id} - ${formattedDate} - ${offer.state}`;
    }
}
